import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { useQuery } from "react-query"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useOfflineState } from "../../hooks/use-offline-state"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import audioPlayer, { Event } from "../../lib/audio-player"
import {
  canDownloadConsumableContent,
  getConsumableContentFromVideoResponse,
} from "../../lib/consumable-content"
import { playContentAudio } from "../../lib/content-audio"
import { formatDate } from "../../lib/date"
import DownloadItem from "../../lib/download-item"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import { AppLink } from "../app-link"
import AsyncIconButton from "../async-icon-button"
import AsyncView, { useAsyncViewProps } from "../async-view"
import { AsyncViewOfflineProvider } from "../async-view-offline-provider"
import Box, { BoxPadding } from "../box"
import CenteredContent from "../centered-content"
import DownloadButton from "../download-button"
import { Heading } from "../heading"
import Icon from "../icon"
import LockedContentButton from "../locked-content-button"
import { MetadataSpacer, MetadataText } from "../metadata"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import Text from "../text"
import TextToggle from "../text-toggle"
import { useTokens } from "../tokens-provider"
import VideoPlayerForConsumableContent from "../video-player-for-consumable-content"

// This visually aligns the left edge of the action buttons with the left edge
// of the video title and description. This is necessary because the action
// buttons have padding built-in.
const ActionButtonContainer = styled(Box)`
  margin-left: -${(props) => props.theme.spacing.xsmall}px;
`

const StyledAppLink = styled(AppLink)`
  width: ${({ theme }) => theme.button.height.medium}px;
  height: ${({ theme }) => theme.button.height.medium}px;
  align-items: center;
  justify-content: center;
`

export function VideoModule({
  contentId,
  maxWidth,
  paddingTop = "medium",
  presentation = "solo",
}: {
  contentId: number
  maxWidth?: number
  paddingTop?: BoxPadding
  presentation?: "solo" | "listItem"
}): JSX.Element {
  const { t, i18n } = useTranslation()
  const profileId = useActiveProfileId()
  const { tokens } = useTokens()
  const [offline] = useOfflineState()
  const videoQueryEnabled = !offline
  const videoQuery = useContent(contentId, "video", {
    enabled: videoQueryEnabled,
  })
  const openCheckoutPage = useOpenCheckoutPage({
    availability: videoQuery.data?.availability,
    contentId,
  })

  // Pull playable content from the API when online and from the local store
  // when offline.
  const consumableContentQuery = useQuery(
    ["videoConsumableContent", offline, videoQuery.data, contentId],
    async () => {
      // Return offline data if a) we don't have data from the server or b)
      // we're actually offline. In the case of "a" we want don't want to make
      // the user wait for a slow network connection if they have the content
      // downloaded. In the case of "b" we want to ignore cached data from the
      // server as that'll include URLs that won't resolve offline.
      if (!videoQuery.data || offline) {
        const item = await DownloadItem.fromContentId(contentId)
        const consumableContent = await item?.getOfflineConsumableContent()
        return consumableContent?.type === "video"
          ? consumableContent
          : undefined
      }

      return getConsumableContentFromVideoResponse(videoQuery.data)
    },
  )

  const consumableContent = consumableContentQuery.data
  const content = consumableContent?.content
  const videoSeries =
    content?.details.videoSeries.length === 1
      ? content.details.videoSeries[0]
      : undefined
  const isLocked = content?.details.videoMedia?.status === "notAvailable"
  const showAddToLibraryButton = canAddToLibrary({
    manifest: useAppManifest(),
    consumableContent,
  })
  const showDownloadButton =
    consumableContent && canDownloadConsumableContent(consumableContent)

  // Fetch data for the next video
  const videoSeriesQuery = useContent(videoSeries?.id, "videoSeries")
  const allEpisodes = videoSeriesQuery.data?.details.seasons.flatMap(
    (season) => season.episodes,
  )
  const currentEpisodeIndex = allEpisodes?.findIndex(
    (episode) => episode.id === content?.id,
  )
  const nextEpisodeId =
    currentEpisodeIndex !== undefined && currentEpisodeIndex > -1
      ? allEpisodes?.[currentEpisodeIndex + 1]?.id
      : undefined
  const nextVideoQuery = useContent(nextEpisodeId, "video")

  const listenToVideo = useCallback(() => {
    if (consumableContent) {
      // Resolve this function either when audio is loaded or when the player
      // signals that it will stop. This ensures that, if the video player takes
      // over and cancels the audio player while it's in the middle of loading,
      // the listen button doesn't hang in a loading state.
      return Promise.race([
        playContentAudio({ consumableContent, profileId }),
        audioPlayer.once(Event.WillStop),
      ])
    }
  }, [consumableContent, profileId])

  const heading = (
    <Heading
      level={2}
      textStyle="headingLarge"
      color="primary"
      maxWidth="title"
    >
      {content?.title || ""}
    </Heading>
  )

  const playButtonStyle = useMemo(
    () =>
      StyleSheet.create({
        styles: {
          flex: tokens.videoModule.fullWidthSignUpButton ? 1 : undefined,
        },
      }).styles,
    [tokens],
  )

  const actionButtons = content?.details.videoMedia ? (
    <Row>
      {content.availability.status === "notAvailable" ? (
        <LockedContentButton
          openCheckoutPage={openCheckoutPage}
          availability={content.availability}
          action="watch"
          style={playButtonStyle}
          containerStyle={playButtonStyle}
        />
      ) : tokens.appHeader.mode === "desktop" &&
        content.details.videoSeries[0] &&
        (videoSeriesQuery.isLoading || nextVideoQuery.isLoading) ? null : (
        <>
          <AsyncIconButton
            source={icons.headphones}
            color="secondary"
            onPress={listenToVideo}
            label={t("Listen")}
            minSize="buttonHeight"
          />
          {showDownloadButton ? (
            <>
              <Spacer size="small" horizontal />
              <DownloadButton consumableContent={consumableContent} />
            </>
          ) : null}
          {showAddToLibraryButton ? (
            <>
              <Spacer size="small" horizontal />
              <AddToLibraryButton consumableContent={consumableContent} />
            </>
          ) : null}
          {nextVideoQuery.data ? (
            <>
              <Spacer size="small" horizontal />
              <StyledAppLink
                aria-label={t("Next")}
                to={{
                  type: "content",
                  contentId: nextVideoQuery.data.id,
                  contentType: "video",
                }}
              >
                <Icon source={icons.skipForwardMini} color="secondary" />
              </StyledAppLink>
            </>
          ) : null}
        </>
      )}
    </Row>
  ) : null

  useAsyncViewProps(
    useAsyncViewPropsForQueries(
      offline ? consumableContentQuery : [videoQuery, consumableContentQuery],
    ),
  )

  return (
    <AsyncViewOfflineProvider
      offlineStateDisabled={
        // Disable the offline screen if we might have downloaded content to
        // show
        (videoQueryEnabled && videoQuery.isLoading) ||
        consumableContentQuery.isLoading ||
        consumableContent !== undefined
      }
      state="success"
    >
      <AsyncView>
        <Box paddingTop={paddingTop}>
          <VideoPlayerForConsumableContent
            consumableContent={consumableContent}
            nextVideo={nextVideoQuery.data}
          />
          <CenteredContent
            paddingTop="large"
            maxWidth={maxWidth || tokens.videoModule.maxWidth}
          >
            {actionButtons && tokens.videoModule.actionButtonsInHeading ? (
              <Row alignItems="flex-start" justifyContent="space-between">
                {heading}
                <Spacer size="medium" horizontal />
                {actionButtons}
              </Row>
            ) : (
              heading
            )}
            {videoSeries ? (
              <>
                <Spacer size="small" />
                <AppLink
                  to={{
                    type: "content",
                    contentId: videoSeries.id,
                    contentType: "videoSeries",
                  }}
                  aria-label={videoSeries.title}
                >
                  <Row>
                    <Text textStyle="body" numberOfLines={1}>
                      {videoSeries.title}
                    </Text>
                    <Spacer size="tiny" horizontal />
                    <Icon source={icons.chevronRight} size="xsmall" />
                  </Row>
                </AppLink>
              </>
            ) : null}
            <Row paddingTop="small">
              {content?.publishedAt ? (
                <MetadataText>
                  {formatDate(new Date(content.publishedAt), {
                    strategy: "fullDate",
                    i18n,
                  })}
                </MetadataText>
              ) : null}
              <ProgressForConsumableContent
                consumableContent={consumableContent}
                includeDurationLabel
                includeFinishedBadge
                childrenBefore={
                  content?.publishedAt ? <MetadataSpacer /> : null
                }
              />
            </Row>
            {content?.publisher && (
              <MetadataText>{content.publisher.name}</MetadataText>
            )}
            {actionButtons && !tokens.videoModule.actionButtonsInHeading ? (
              <ActionButtonContainer
                paddingTop={isLocked ? "medium" : "xsmall"}
              >
                {actionButtons}
              </ActionButtonContainer>
            ) : null}
            {content?.description ? (
              <Box
                paddingTop={
                  actionButtons &&
                  !tokens.videoModule.actionButtonsInHeading &&
                  !isLocked
                    ? "xsmall"
                    : "large"
                }
              >
                <TextToggle
                  textStyle="body"
                  numberOfLines={presentation === "solo" ? 6 : 2}
                  withLinks
                >
                  {content.description}
                </TextToggle>
              </Box>
            ) : null}
          </CenteredContent>
        </Box>
      </AsyncView>
    </AsyncViewOfflineProvider>
  )
}
