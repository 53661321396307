import React from "react"
import { AppRegistry } from "react-native"
import { BrowserRouter } from "react-router-dom"

import { Integrations } from "@sentry/tracing"
import smoothscroll from "smoothscroll-polyfill"

import appJson from "./app.json"
import App from "./src/app"
import "./src/assets/fonts/fonts.css"
import { initializeLogging } from "./src/lib/logging-initialize"
import { registerServiceWorker } from "./src/lib/service-worker-registration.web"

function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

const appName = appJson.expo.name

smoothscroll.polyfill()

initializeLogging({
  integrations: [new Integrations.BrowserTracing()],
})

registerServiceWorker()

AppRegistry.registerComponent(appName, () => Root)

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById("root"),
})
