import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { useActiveProfileId } from "../../../hooks/use-active-profile-id"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import useContent from "../../../hooks/use-content"
import { useTfEffectEvent } from "../../../hooks/use-effect-event"
import { useOpenCheckoutPage } from "../../../hooks/use-open-checkout-page"
import useSafeAreaInsetAppFooter from "../../../hooks/use-safe-area-inset-app-footer"
import useWindowDimensions from "../../../hooks/use-window-dimensions"
import analytics from "../../../lib/analytics"
import { formatDate } from "../../../lib/date"
import { WebEmbedProgressItem } from "../../../lib/progress-item"
import { useAsyncViewProps } from "../../async-view"
import Box from "../../box"
import CenteredContent from "../../centered-content"
import { Heading } from "../../heading"
import LockedContentButton from "../../locked-content-button"
import { MetadataText } from "../../metadata"
import ModuleArtworkLayout from "../../module-artwork-layout"
import Row from "../../row"
import Spacer from "../../spacer"
import TextToggle from "../../text-toggle"
import { useTokens } from "../../tokens-provider"
import { WebEmbedContent } from "./web-embed-content"

export function WebEmbedModule({
  contentId,
  paddingTop,
  presentation = "solo",
  maxWidth = 960,
}: {
  contentId: number
  paddingTop: number
  presentation?: "solo" | "listItem"
  maxWidth?: number
}) {
  const { i18n } = useTranslation()
  const { tokens } = useTokens()
  const [artworkReady, setArtworkReady] = useState(false)
  const [embedReady, setEmbedReady] = useState(false)
  const windowDimensions = useWindowDimensions({ updateOnHeightChange: true })
  const content = useContent(contentId, "webEmbed")
  const profileId = useActiveProfileId()
  const mobileFooterHeight = useSafeAreaInsetAppFooter()
  const openCheckoutPage = useOpenCheckoutPage({
    availability: content.data?.availability,
    contentId,
  })

  const inlineDimensions = useMemo(
    () => ({
      width: windowDimensions.width,
      // Display at a 16:9 aspect ratio but constrain the height to 2/3 of the
      // window height.
      height: Math.min(
        windowDimensions.height * (2 / 3),
        windowDimensions.width * (9 / 16),
      ),
    }),
    [windowDimensions],
  )

  const footerHeight =
    tokens.appFooter.mode === "desktop"
      ? 0 // The page footer is hidden for webEmbed content on desktop
      : mobileFooterHeight
  const webEmbedMedia = content.data?.details.webEmbedMedia

  // An effect event to log read requests
  const onView = useTfEffectEvent(() => {
    if (content.data) {
      analytics.logReadRequest({
        consumableContent: { type: "webEmbed", content: content.data },
        progressItem: new WebEmbedProgressItem({
          data: { contentId: content.data.id, contentType: "webEmbed" },
          profileId,
        }),
      })
    }
  })

  // Log a read request whenever embedReady changes to true
  useEffect(() => {
    if (embedReady) {
      onView()
    }
  }, [embedReady, onView])

  useAsyncViewProps(
    useAsyncViewPropsForQueries(content, {
      forceLoading:
        webEmbedMedia?.status === "notAvailable" ? !artworkReady : !embedReady,
    }),
  )

  return webEmbedMedia?.status === "available" &&
    content.data?.details.display === "fullscreen" ? (
    <Box paddingTop={paddingTop}>
      <WebEmbedContent
        contentId={content.data?.id}
        media={webEmbedMedia.data}
        onReady={() => setEmbedReady(true)}
        dimensions={{
          height: windowDimensions.height - paddingTop - footerHeight,
          width: windowDimensions.width,
        }}
      />
    </Box>
  ) : webEmbedMedia?.status === "available" &&
    content.data?.details.display === "inline" ? (
    <Box paddingTop={paddingTop}>
      <WebEmbedContent
        contentId={content.data?.id}
        media={webEmbedMedia.data}
        onReady={() => setEmbedReady(true)}
        dimensions={inlineDimensions}
      />
      <CenteredContent paddingTop="large" maxWidth={maxWidth}>
        <Heading
          level={2}
          textStyle="headingLarge"
          color="primary"
          maxWidth="title"
        >
          {content.data.title}
        </Heading>
        <Row paddingTop="small">
          {content.data.publishedAt ? (
            <MetadataText>
              {formatDate(new Date(content.data.publishedAt), {
                strategy: "fullDate",
                i18n,
              })}
            </MetadataText>
          ) : null}
        </Row>
        {content.data.publisher && (
          <MetadataText>{content.data.publisher.name}</MetadataText>
        )}
        {content.data.description ? (
          <Box paddingTop={"large"}>
            <TextToggle
              textStyle="body"
              numberOfLines={presentation === "solo" ? 6 : 2}
              withLinks
            >
              {content.data.description}
            </TextToggle>
          </Box>
        ) : null}
      </CenteredContent>
    </Box>
  ) : webEmbedMedia?.status === "notAvailable" ? (
    <ModuleArtworkLayout
      // Extra padding on top to make up for the squashed header
      paddingTop={paddingTop + tokens.spacing.large}
      artwork={content.data?.artworkMedia?.original.url}
      onArtworkReady={setArtworkReady}
      maxWidth={maxWidth}
    >
      {({ layout }) => (
        <>
          <Heading
            level={2}
            textStyle="headingLarge"
            color="primary"
            maxWidth="title"
          >
            {content.data?.title || ""}
          </Heading>
          <Spacer size="small" />
          <Row>
            {content.data?.publishedAt ? (
              <MetadataText>
                {formatDate(new Date(content.data.publishedAt), {
                  strategy: "fullDate",
                  i18n,
                })}
              </MetadataText>
            ) : null}
          </Row>
          <Spacer size="medium" />
          <Row>
            <LockedContentButton
              openCheckoutPage={openCheckoutPage}
              availability={webEmbedMedia}
              action="view"
            />
          </Row>
          {content.data?.description ? (
            <>
              <Spacer size="medium" />
              <TextToggle
                textStyle="body"
                numberOfLines={
                  presentation === "listItem" && layout === "portrait" ? 2 : 6
                }
                maxWidth="description"
                withLinks
              >
                {content.data.description}
              </TextToggle>
            </>
          ) : null}
        </>
      )}
    </ModuleArtworkLayout>
  ) : null
}
